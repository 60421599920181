<template>
  <div class="wrapper">
    <ul class="allinput">
      <li>
        <img class="h-img" src="http://h5.yihu365.cn/page/images/icon1.png" />
        <input id="name" type="text" placeholder="请输入姓名" v-model="name">
      </li>
      <li>
        <img class="h-img" src="http://h5.yihu365.cn/page/images/icon2.png" />
        <input id="idcard" type="text" maxlength="18" placeholder="请输入身份证号" v-model="card">
      </li>
      <li id="gender" class="gender">
        <img class="h-img" src="http://h5.yihu365.cn/page/images/icon3.png" />
        <label class="sex" id="sex">性别</label>
        <input class="check" type="radio" id="nan" name="sex" value="0" v-model="sex">
        <label for="nan" class="check">男</label>
        <input class="check" type="radio" id="nv" name="sex" value="1" v-model="sex">
        <label for="nv" class="check">女</label>
      </li>
      <li style="height: auto">
        <div class="clearfix">
          <img class="h-img" style="top:13px;transform: translateY(0)" src="http://h5.yihu365.cn/page/images/icon6.png" />
          <label id="about" class="sex" style="margin: 0">与患者关系：{{relationText}}</label>
        </div>
        <p class="guanxi clearfix">
          <span :class=" {'active' : relationText == item.text} " v-for="(item,index) in relArr" :key="item.name" @click="onItemClick(item,index)">{{ item.text }}</span>
        </p>
      </li>

    </ul>
    <div class="center-txt" id="center-txt">
      <textarea id="note" rows="3" placeholder="请详细描述一下患者的疑问或症状、疾病和身体情况" v-model="ques"></textarea>
      <ul class="bixu">
        <li id="dazhen">
          <span class="sex">是否有耗材包？</span>
          <input style="padding-left: 0%" class="check" type="radio" id="3" name="dazhen" value="1" v-model="consumable">
          <label for="3" class="check" @click="showHModel">有</label>
          <input style="padding-left: 0%" class="check" type="radio" id="4" name="dazhen" value="0" v-model="consumable">
          <label for="4" class="check" @click="onClickHao">无</label>

        </li>
        <div class="h-cai" v-if="showHc">
            <div class="list" v-for="(item,index) in consumptionDetail" :key="item.pkgId">
              <input class="check" style="padding-left: 0%" type="radio" :id="item.pkgId" name="hcai" :value="item" v-model="pkgObj">
              <label class='check' :for="item.pkgId">{{ item.pkgName}}({{item.pkgPriceSum}}元)</label>
            </div>
        </div>
        <li id="yaopin">
          <span class="sex">是否有必备药品？</span>
          <input style="padding-left: 0%" class="check" type="radio" id="5" name="yaopin" value="1" v-model="mustTool">
          <label for="5" class="check">有</label>
          <input style="padding-left: 0%" class="check" type="radio" id="6" name="yaopin" value="0" v-model="mustTool">
          <label for="6" class="check">无</label>

        </li>
      </ul>
    </div>
    <div class="tijiao">
      <p>【医护到家】将免费为您提供<a href="http://m.yihu365.cn/hzb/message/renbao.shtml">中国人保意外综合险</a>和<a href="http://m.yihu365.cn/hzb/message/pingan.shtml">平安意外伤害保险,平安个人责任保险</a></p>
      <button class="btn" @click="submin">确 定</button>
<!--      <div>{{urls}}</div>-->
    </div>
  </div>
</template>
<!--<script src="https://www.daoway.cn/js/daowayCallBack.js"></script>-->
<script>

// import 'https://www.daoway.cn/js/daowayCallBack.js';
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog ,Toast} from 'vant'
import homeApi from '@axios/home'
import loginApi from '@axios/login'
export default {
  name: "sample",
  setup(){

     const route = useRoute();
     const name = ref('')
     const card = ref('');//
     const sex = ref('');
     const relation = ref('');
     const relationText = ref('自己');
     const ques = ref('');
     const consumable = ref('');//耗材
     const mustTool = ref('')//必备
     const showHc = ref(false)
     const pkgObj = ref({})


    onMounted(()=> {

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://www.daoway.cn/js/daowayCallBack.js';
      document.body.appendChild(script);
    })
    const relArr = ref([
       {
         text:'自己',
         name:'my'
       },
       {
         text:'爸爸',
         name:'father'
       },
       {
         text:'妈妈',
         name:'mamma'
       },
       {
         text:'孩子',
         name:'child'
       },
       {
         text:'亲戚',
         name:'relative'
       },
       {
         text:'朋友',
         name:'friend'
       },
       {
         text:'其他',
         name:'other'
       }
     ])


    const showHModel =() => {
      showHc.value = false;
      pkgObj.value ={};
    }
    const consumptionDetail = ref([])
     let queryStr = JSON.parse(route.query.daoway_params);

    let codeArr = queryStr.order_item[0].third_id.split('-');
    let count = queryStr.order_item[0].item_count;
     console.log(queryStr)
     console.log(count)
    const onClickHao =()=> {
      showHc.value = true;
      if(consumptionDetail.value.length>0){return;}
      let formData = new FormData();
      formData.append('data',JSON.stringify({
        function : 'getServiceConsumPkg',
        role_code : codeArr[0],
        firSvCode : codeArr[1],
        scdSvCode : "",
        times : count + ''
      }))

      formData.append('encryption',false)

      loginApi.loginAction(formData).then((res)=>{
        console.log(res);
        consumptionDetail.value = res.data;

        if(consumable.value == 0){//如果选择'无耗材'
          let result =  consumptionDetail.value.find((ele)=> {
            return ele.pkgId == pkgObj.value.pkgId
          })
          pkgObj.value = result;
          console.log('result',result)
        }

      })
    }
    const isActive = ref(0)
    const onItemClick =(item,index) => {
       relationText.value = item.text;
       isActive.value = index;

    }

    // 数据回填
    const getHistoryData = () => {
       const params ={
          phone:queryStr.phone
       }
      homeApi.getDaoweiUserInfo(params).then((res)=> {
        console.log(res)
        if(res.data){
          let data = JSON.parse(res.data);
          // let data = {
          //   'realname':'xsl' ,
          //   'idCardNo': '42058119930124221X',
          //   'sex': '0',
          //   'relationship': '孩子',
          //   'note': 'xxe45',
          //   'isHasTool':'1',
          //   'isHasMedicina':'0',
          //   'pkgId':'',//耗材包
          //   'pkgNum': '',//耗材包
          // };
          name.value = data.realname
          card.value = data.idCardNo
          sex.value =data.sex
          relationText.value = data.relationship
          ques.value = data.note
          consumable.value = data.isHasTool
          mustTool.value = data.isHasMedicina
          pkgObj.value.pkgId = data.pkgId;

          if(data.isHasTool == 0){
            onClickHao();
          }
        }

       })

    }
    getHistoryData();
    const submin =()=> {
       console.log('must',mustTool.value)
      let cardreg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if(!name.value){
        Toast('请输入姓名');
        return;
      }
      if(!card.value){
        Toast('请输入身份证号码');
        return;
      }
      if(!cardreg.test(card.value)){
        Toast('身份证号码格式不正确');
        return;
      }
      if(!sex.value){
        Toast('请选择性别');
        return;
      }
      if(!consumable.value){
        Toast('是否有耗材包');
        return;
      }
      if(consumable.value == '0' && !pkgObj.value.pkgId ){
        Toast('请选择耗材包');
        return;
      }
      if(!mustTool.value){
        Toast('没有药品（除葡萄糖和生理盐水外），平台不提供服务。请自备药品。');
        return;
      }
      let param = {
        'realname': name.value,
        'idCardNo': card.value,
        'sex': sex.value,
        'relationship': relationText.value,
        'note': ques.value,
        'isHasTool':consumable.value,
        'isHasMedicina': mustTool.value,
        'pkgId':pkgObj.value.pkgId ? pkgObj.value.pkgId : '',//耗材包
        'pkgNum':pkgObj.value.pkgNum ? pkgObj.value.pkgNum : '',//耗材包
      };

      let pkgSum = 0;
      if(param.isHasTool == 0){
        pkgSum = Number(pkgObj.value.pkgPriceSum);
      }
      console.log('params',param);
      console.log('pkgObj',pkgObj.value);
      console.log('pkgSum',pkgSum);

      let params = {
          phone:queryStr.phone,
          value:param
      }
      homeApi.getDaoweiUserInfo(params).then((res)=> {
        console.log(res)
      })
        daowayCallBack(pkgSum, JSON.stringify(param))

    }

    return{
      name,
      card,
      sex,
      relation,
      ques,
      consumable,
      mustTool,
      relationText,
      submin,
      relArr,
      onClickHao,
      showHc,
      showHModel,
      consumptionDetail,
      onItemClick,
      isActive,
      pkgObj
    }

  }
}

</script>

<style scoped lang="scss">
.active{
  background-color: #1cc5a4;
  color:#fff;
}
.h-img{
  width:40px;
}
.wrapper{
  box-sizing: border-box;
  padding:10px 15px;
}
input {
  //height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.allinput {
  background: #fff
}
.allinput li{
  padding-left:20px;
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;

}
 .bixu li {
   box-sizing: border-box;
  padding-left:20px;
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #e5e5e5;

}
.gender{
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  align-items: center;
}
.bixu li{
  display: flex;
  display: -moz-flex;
  display: -webkit-flex;
  align-items: center;
}
.allinput li{
  position:relative;
  padding-left:76px;
  padding-right:30px;
  box-sizing: border-box;
}


.allinput li:last-child {
  border: none
}

.allinput input {
  border: none;
  width: 100%;
  font-size: 30px;
  float:left;
  height: 70px;
}

.allinput img {
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  left:20px;
}

.allinput .gender input,
.bixu input {
  width: 0;
  height: 0
}
.h-cai .list{
  line-height: 80px;
}
.h-cai input {
  width: 0;
  height: 0
}
.check {
  background: url("http://h5.yihu365.cn/page/images/icon4.png") no-repeat;
  height: 44px;
  line-height: 44px;
  padding-left: 50px;
  display: inline-block;
  background-size: 44px 44px;
  vertical-align: middle;
  margin-left: 12px;
  font-size: 26px;
  border: none;
  /*float:left;*/
  margin-top:10px;
}

input[type='radio'].check:checked+.check {
  background: url("http://h5.yihu365.cn/page/images/icon5.png") no-repeat;
  background-size: 44px 44px
}

.sex {
  /*margin-right: 34%;*/
  color: #a9a9a9;
  font-size: 30px;
  /*float:left;*/
}

.guanxi {
  width: 100%;
  margin: 0 auto 20px;
  background: #fff;
  padding-bottom: 20px;
}

.clearfix::after{
  display: block;
  content:'';
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
}

.guanxi span {
  width: 21%;
  display: inline-block;
  text-align: center;
  border: 1px solid #e5e5e5;
  margin-right: 2.7%;
  border-radius: 12px;
  font-size: 26px;
  height: 60px;
  line-height: 60px;
  float:left;
  margin-bottom:20px;
}

.active {
  background: #1cc5a4;
  color: #fff
}

.center-txt {
  width: 100%;
  margin-top: 20px;
  background: #fff;
  padding-top: 0.5rem
}

.center-txt textarea {
  background: #f1f2f6;
  color: #bbbbbb;
  line-height: 40px;
  font-size: 26px;
  margin: 0 2%;
  width: 92%;
  padding: 2%;
  border: none;
}

.center-txt .sex {
  /*margin-right: 23%*/
}

.tijiao {
  width: 96%;
  background: #fff;
  padding: 20px 2% 30px;
  line-height: 30px;
  margin-top: 20px;
}

.tijiao p a {
  color: #1cc5a4;
  font-size: 24px;
}

.tijiao p {
  font-size: 26px
}

.btn {
  width: 96%;
  margin: 20px 2% 0;
  height: 80px;
  line-height: 80px;
  background: #1cc5a4;
  border: none;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  font-size: 30px
}


input {
  outline: medium none;
}
</style>
